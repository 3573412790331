/* ─────────────────────────────
   /src/components/AffiliateLink.tsx
   ───────────────────────────── */
"use client";

import React from "react";

interface AffiliateLinkProps {
  href: string;
  affiliateId: string;
  children: React.ReactNode;
}

export function AffiliateLink({
  affiliateId,
  children,
  href,
}: AffiliateLinkProps) {
  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // prevent the default navigation
    e.preventDefault();
    try {
      // サーバー側のAPIルートを呼び出し、HttpOnly クッキーを設定する
      await fetch(`/api/set-affiliate-cookie?affiliateId=${affiliateId}`, {
        method: "GET",
        credentials: "same-origin", // クッキーのやり取りが同一オリジンの場合有効
      });
    } catch (error) {
      console.error("Error setting affiliate cookie:", error);
    }
    // クッキー設定後、新しいタブでリンク先を開く
    window.open(href, "_blank", "noopener,noreferrer");
  };

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  );
}
